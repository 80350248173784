<template>
  <div class="content">
    <!-- 好评弹窗 -->
    <van-dialog
      class="commentDialog"
      v-model="commentVisible"
      :showConfirmButton="false"
    >
      <template #title>
        <p class="rise-limit-title">Beri ulasan bintang 5</p>
      </template>
      <p class="tip">
        Berharap Anda bisa memberi saya ulasan bintang lima. Lain kali saya akan
        memberi Anda kejutan berupa peningkatan limit pinjaman.
      </p>
      <button type="button" class="toStore-btn" @click="toStore()">
        Beri nilai
      </button>
      <img
        class="hiddenIcon"
        src="@/assets/img/hiddenIcon.png"
        @click="toStore(false)"
      />
    </van-dialog>
    <!-- 券 -->
    <van-dialog
      class="ticketDialog"
      v-model="ticketVisible"
      :showConfirmButton="false"
    >
      <div class="ticket">
        <h2>{{ money }}</h2>
        <img
          class="ticket_btn"
          src="@/assets/img/ticket_btn.png"
          alt=""
          @click="ticketClose"
        />
        <img
          class="ticket_close"
          src="@/assets/img/ticket_close.png"
          alt=""
          @click="ticketClose"
        />
      </div>
    </van-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { Dialog, Toast } from 'vant'
Vue.use(Toast)
export default {
  props: {
    money: {
      default: 'Rp0'
    }
  },
  data () {
    return {
      commentVisible: false,
      ticketVisible: false
    }
  },
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  created () {
    // 券
    if (!localStorage.getItem('showTicket')) {
      this.ticketVisible = true
      return
    }
    // 好评弹窗
    if (!localStorage.getItem('showEvaluate')) {
      this.commentVisible = true
    }
  },
  methods: {
    // 券弹窗关闭
    ticketClose () {
      Toast('Berhasil ambil, gunakan pada pinjaman berikutnya')
      localStorage.setItem('showTicket', true)
      setTimeout(() => {
        this.ticketVisible = false
      }, 700)
      if (!localStorage.getItem('showEvaluate')) {
        this.commentVisible = true
      }
    },
    // 跳转应用商店
    toStore (isToStore = true) {
      if (isToStore) {
        const apitype = sessionStorage.getItem('apitype') || '1'
        if (apitype === '1') {
          window.location = 'https://apps.apple.com/id/app/buku-dana/id6475670138?l=id'
          return
        }
        if (apitype === '2') {
          window.location = 'https://apps.apple.com/id/app/floushin-pinjaman-online/id6502891760?l=id'
          return
        }
      }
      this.commentVisible = false
      localStorage.setItem('showEvaluate', true)
    }
  }
}
</script>

<style scoped lang='less'>
.commentDialog {
  .rise-limit-title {
    .dialog-title;
    font-size: 28px;
    padding-left: 24px;
    text-align: left;
  }

  .tip {
    padding-left: 49px;
    padding-right: 49px;
    margin-top: 16px;
    font-size: 26px;
    color: #999;
    text-align: center;
  }

  .confirm-btn {
    width: 634px;
    height: 88px;
    line-height: 88px;
    border-radius: 8px;
    margin: 80px auto 84px;
    font-size: 30px;
    color: #fff;
    background-color: @theme-color;
  }
  .hiddenIcon {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}
.ticketDialog {
  background: none;
  padding-bottom: 250px;

  .ticket {
    height: 900px;
    background: none;
    position: relative;
    background: url("~@/assets/img/ticket_bg.png") no-repeat;
    background-size: contain;
    background-position: center;
    z-index: 9;
    h2 {
      text-align: center;
      padding-top: 650px;
      color: #ea0923;
      font-size: 36px;
    }
    img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .ticket_btn {
      bottom: -110px;
      height: 100px;
    }
    .ticket_close {
      bottom: -250px;
      width: 100px;
    }
  }
}
/deep/ .van-overlay {
  background-color: rgba(0, 0, 0, 0.85);
}
</style>
